.fix-table-header thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ededed;
}
.fix-table-header table {
  border-collapse: separate;
}

.custom-no-option-autocomplete .MuiAutocomplete-noOptions {
  display: none !important;
}
td:has(a.NEW),
td:has(img.NEW),
td:has(span.NEW) {
  border-left: 3px solid #8c64dc !important;
}
td:has(a.PENDING),
td:has(img.PENDING),
td:has(span.PENDING) {
  border-left: 3px solid #e0e0e0 !important;
}
td:has(a.PROCESSING),
td:has(img.PROCESSING),
td:has(span.PROCESSING) {
  border-left: 3px solid #ffb41f !important;
}
td:has(a.PROCESSED_FAIL),
td:has(img.PROCESSED_FAIL),
td:has(span.PROCESSED_FAIL) {
  border-left: 3px solid #ef1446 !important;
}
td:has(a.IMPORTING),
td:has(img.IMPORTING),
td:has(span.IMPORTING) {
  border-left: 3px solid #ffb41f !important;
}
td:has(a.PROCESSED),
td:has(img.PROCESSED),
td:has(span.PROCESSED) {
  border-left: 3px solid #53c479 !important;
}
